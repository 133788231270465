@font-face {
  font-family: 'Avant Garde for TVK';
  src: url('../assets/fonts/AvantGardeforTVK-Book.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Avant Garde for TVK';
  src: url('../assets/fonts/AvantGardeforTVK-XLight.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Avant Garde for TVK';
  src: url('../assets/fonts/AvantGardeforTVK-Demi.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Avant Garde for TVK';
  src: url('../assets/fonts/AvantGardeforTVK-Bold.otf') format('opentype');
  font-weight: 700;
}

$white: #fff;
$black: #000;
$gray: #545454;
$gray-2: #444;
$gray-3: #dfdfdf;
$gray-4: #f2f2f2;
$ghost-gray: #bebebe;
$light-gray: #e7e7e7;
$light-gray-2: #f2f2f2;
$dark-gray: #2f3335;
$red: #ed4c39;
$red-2: #ff3b39;
$magenta: #c41745;
$green: #408833;
$green-2: #56bd66;
$blue: #1f92df;
$yellow: #e1841e;
$cyan: #00e1d4;
