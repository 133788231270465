@import 'conf';

.company-theme-black {
  .portal-section-title {
    background: $dark-gray;

    &-refresh {
      fill: $red;
    }
  }
}

.company-theme-red {
  .portal-section-title {
    background: $red;

    &-refresh {
      fill: $dark-gray;
    }
  }
}
